import { Injectable } from '@angular/core';

@Injectable()
export class RouteConstant {

  private routeConfig: any;

  constructor() {
    this.routeConfig = {
      valid_routes: ['login', 'logout', 'dashboard', '/team/members', 'workOrder', '/user/profile', '/notification/list']
    };
  }

  get() {
    return this.routeConfig;
  }
}
