import {Component, Injectable, OnInit} from '@angular/core';
import {Router, ActivatedRoute,RoutesRecognized,NavigationEnd } from '@angular/router';


import {AuthenticationService} from '../_services';

import 'rxjs/add/operator/filter';
@Component({
  templateUrl: 'signout.component.html',
  providers: [AuthenticationService]
})


export class SignOutComponent implements OnInit {

  previousUrl:any;
  constructor(private route: ActivatedRoute,
              private router: Router,
              private authenticationService: AuthenticationService) {

  }

  ngOnInit() {

    // reset signin status

    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
        //  console.log(event.url);
        }
      });



    this.authenticationService.logout().then((res) =>{
        console.log('res',res);
        if(res){
          this.router.navigate(['/login']);
        }
    });
   // console.log('here' + localStorage.getItem('currentUserZahra'));

  }

}
