import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {throwError, of} from 'rxjs';
import {APIConstants} from '../shared/config/api-constant';

@Injectable()
export class DashboardService {

  private API: any;
  private currentUser: any;
  private accessToken: string;

  constructor(private http: HttpClient, private APIConfig: APIConstants) {
    this.API = APIConfig.get();
    this.currentUser = JSON.parse(localStorage.getItem('currentUserZahra'));
    this.accessToken = `Bearer ${this.currentUser.accessToken}`;


  }

  private getUrl(endpoint: string, params: string[] = []) {
    return this.API.BASE_URL + endpoint + ((params.length > 0) ? '/' + params.join('/') : '');
  }


  getWorkOrderAssignmentList(workorder?:any) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };


    if(workorder){
      return this.http
        .post<any>(this.getUrl(this.API.DASHBOARD_GET_WORK_ORDER_ASSIGNEMTS),workorder, httpOptions)
        .pipe(
          map((data: any) => {
            if (data.success) {
              return data.data;
            }
            return data;
          })
        ).pipe(
          catchError(this.handleErrorRecord('No Work Order Found', []))
        );

    }else{
      return this.http
        .post<any>(this.getUrl(this.API.DASHBOARD_GET_WORK_ORDER_ASSIGNEMTS),[] ,httpOptions)
        .pipe(
          map((data: any) => {
            if (data.success) {
              return data.data;
            }
            return data;
          })
        ).pipe(
          catchError(this.handleErrorRecord('No Work Order Found', []))
        );
    }


  }
  getTeamAssignmentList(team?:any) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };



    if(team){

      return this.http
        .post<any>(this.getUrl(this.API.DASHBOARD_GET_TEAM_ASSIGNEMTS),team, httpOptions)
        .pipe(
          map((data: any) => {
            if (data.success) {
              return data.data;
            }
            return data;
          })
        ).pipe(
          catchError(this.handleErrorRecord('No Work Order Found', []))
        );
    }else{
      return this.http
        .post<any>(this.getUrl(this.API.DASHBOARD_GET_TEAM_ASSIGNEMTS),[], httpOptions)
        .pipe(
          map((data: any) => {
            if (data.success) {
              return data.data;
            }
            return data;
          })
        ).pipe(
          catchError(this.handleErrorRecord('No Work Order Found', []))
        );
    }


  }
  getProductAssignmentList(product?:any) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    if(product){

      return this.http
        .post<any>(this.getUrl(this.API.DASHBOARD_GET_PRODUCT_ASSIGNEMTS),product, httpOptions)
        .pipe(
          map((data: any) => {
            if (data.success) {
              return data.data;
            }
            return data;
          })
        ).pipe(
          catchError(this.handleErrorRecord('No Work Order Found', []))
        );
    }else{
      return this.http
        .post<any>(this.getUrl(this.API.DASHBOARD_GET_PRODUCT_ASSIGNEMTS),[], httpOptions)
        .pipe(
          map((data: any) => {
            if (data.success) {
              return data.data;
            }
            return data;
          })
        ).pipe(
          catchError(this.handleErrorRecord('No Work Order Found', []))
        );
    }


  }
  getWorkOrderList() {

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };


    return this.http
      .get(this.getUrl(this.API.DASHBOARD_GET_WORKORDERS), httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data.data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('No Work Order Found', []))
      );
  }
  getStockList() {

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };


    return this.http
      .get(this.getUrl(this.API.DASHBOARD_GET_STOCK), httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data.data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('No Work Order Found', []))
      );
  };

  getTeams() {

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http
      .get(this.getUrl(this.API.GET_TEAM), httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data.data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('No Team Found', []))
      );
  }

  getPendingWorkOrderByOperations(operation:any){

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };


    return this.http
      .post<any>(this.getUrl(this.API.WORK_ORDER_BY_OPERATIONS),operation, httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data.data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('No Team Found', []))
      );

  }


  getPendingWorkOrderByTeam(operation:any){

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };


    return this.http
      .post<any>(this.getUrl(this.API.WORK_ORDER_BY_TEAM),operation, httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data.data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('No Team Found', []))
      );

  }


  getPendingWorkOrderByTeamList(operation:any){

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };


    return this.http
      .post<any>(this.getUrl(this.API.WORK_ORDER_BY_TEAM_LIST),operation, httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data.data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('No Team Found', []))
      );

  }



  getInventoryWorkOrderByClient(){

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };


    return this.http
      .get(this.getUrl(this.API.INVENTORY_WORK_ORDER_BY_CLIENT), httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data.data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('No Records Found', []))
      );

  }


  getInventoryWorkOrderByClientList(clientType){

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };


    return this.http
      .get(this.getUrl(this.API.INVENTORY_WORK_ORDER_BY_CLIENT_LIST + clientType), httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data.data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('No Records Found', []))
      );

  }


  getInventoryWorkOrderByClientCategory(clientType,filter){

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http
      .get(this.getUrl(this.API.INVENTORY_WORK_ORDER_BY_FILTER + clientType + '/' + filter), httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data.data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('No Records Found', []))
      );

  }
  getInventoryWorkOrderByClientCategoryByProduct(clientType,filter,categoryId){

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http
      .get(this.getUrl(this.API.INVENTORY_WORK_ORDER_BY_FILTER + clientType + '/' + filter + '?category_id=' +categoryId), httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data.data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('No Records Found', []))
      );

  }
  getInventoryWorkOrdersByProduct(clientType,filter,categoryId,productId){

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http
      .get(this.getUrl(this.API.INVENTORY_WORK_ORDER_BY_FILTER + clientType + '/' + filter + '?category_id=' +categoryId + '&product_id=' + productId), httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data.data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('No Records Found', []))
      );

  }





  private handleErrorRecord<T>(operation = 'operation', result?: T) {
    return (error: any): any => {

      // TODO: send the error to remote logging infrastructure
      console.log(error.error); // log to console instead

      if (error.error.summary === 'Record Not Found' || error.error.summary === 'Requested API Not Found') {
        return of([]);
      }


      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(error.error as T);
    };
  }

  private handleError(error: HttpErrorResponse) {
    let err;
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      err = 'An error occurred: ' + error.error.message;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      err = error;
    }
    console.log(err);
    // return an observable with a user-facing error message
    // return throwError('Something bad happened; please try again later.');
    return throwError(err);
  }
}
