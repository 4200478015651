import { Component } from '@angular/core';
import { TemplateService } from '../../shared/services/template.service';
import { trigger, state, style, animate, transition, query, stagger } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';


@Component({
  selector: 'app-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.css'],
  animations: [

    trigger('fadeInUp', [
      state('void', style({
        opacity: 0,
      })),
      transition('void <=> *', animate('0.6s ease-in', style({ opacity: 0, transform: 'translateY(-100%)' }))),

    ]),

    trigger('EnterLeave', [
      state('flyIn', style({ transform: 'translateX(0)' })),
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('0.5s 300ms ease-in')
      ]),
      transition('void <=> *', [
        animate('0.3s ease-out', style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]

})
export class SidePanelComponent {

  isOpen: boolean;



  constructor(private tplSvc: TemplateService, private route: ActivatedRoute, private router: Router) {


  }

  ngOnInit(): void {
    this.tplSvc.isSidePanelOpenChanges.subscribe(isOpen => this.isOpen = isOpen);


  }



  toggleSidePanelOpen() {
    this.isOpen = !this.isOpen;
    this.tplSvc.toggleSidePanelOpen(this.isOpen);
  }



}


