import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {throwError, of} from 'rxjs';
import {APIConstants} from '../shared/config/api-constant';

@Injectable()
export class WorkOrderConfigService {

  private API: any;
  private currentUser: any;
  private accessToken: string;

  constructor(private http: HttpClient, private APIConfig: APIConstants) {
    this.API = APIConfig.get();
    this.currentUser = JSON.parse(localStorage.getItem('currentUserZahra'));
    this.accessToken = `Bearer ${this.currentUser.accessToken}`;
  }

  private getUrl(endpoint: string, params: string[] = []) {
    return this.API.BASE_URL + endpoint + ((params.length > 0) ? '/' + params.join('/') : '');
  }


  getWorkOrderOperations() {

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http
      .get(this.getUrl(this.API.GET_WORK_ORDER_OPERATIONS), httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data.data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('No Work Order Operations Found', []))
      );
  }

  getOperations() {

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http
      .get(this.getUrl(this.API.GET_OPERATIONS), httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data.data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('No Work Order Operations Found', []))
      );
  }

  getWorkOrderOperationsType() {

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http
      .get(this.getUrl(this.API.GET_WORK_ORDER_TYPE), httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data.data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('No Work Order Operations Found', []))
      );
  }


  addWorkOrderOperations(WorkOrderOperation: any) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    console.log(this.accessToken);
    return this.http
      .post<any>(this.getUrl(this.API.CREATE_WORK_ORDER_OPERATION), WorkOrderOperation, httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data.data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('Add Product Error', []))
      );
  }
  addOperations(Operation: any) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    console.log(this.accessToken);
    return this.http
      .post<any>(this.getUrl(this.API.CREATE_OPERATION), Operation, httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data.data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('Add Product Error', []))
      );
  }
  addWorkOrderOperationsType(WorkOrderOperationsType: any) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    console.log(this.accessToken);
    return this.http
      .post<any>(this.getUrl(this.API.CREATE_WORK_ORDER_TYPE), WorkOrderOperationsType, httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data.data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('Add Product Error', []))
      );
  }


  editWorkOrderOperationsType(WorkOrderType_Id: any, WorkOrderType: any) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http
      .put<any>(this.getUrl(this.API.EDIT_WORK_ORDER_TYPE, [WorkOrderType_Id.toString()]), WorkOrderType, httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data.data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('Edit Product Error', []))
      );
  };
  editOperations(Operations_Id: any, Operations: any) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http
      .put<any>(this.getUrl(this.API.EDIT_OPERATION, [Operations_Id.toString()]), Operations, httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data.data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('Edit Operations Error', []))
      );
  };
  editWorkOrderOperations(WorkOrderOperation_Id: any, WorkOrderOperations: any) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http
      .put<any>(this.getUrl(this.API.EDIT_WORK_ORDER_OPERATION, [WorkOrderOperation_Id.toString()]), WorkOrderOperations, httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data.data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('Edit Operations Error', []))
      );
  };



  deleteOperations(Operation_Id: any) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http
      .get(this.getUrl(this.API.DELETE_OPERATION, [Operation_Id.toString()]), httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data.data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('Edit Product Error', []))
      );
  }
  deleteWorkOrderOperations(WorkOrderOperation_Id: any) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http
      .get<any>(this.getUrl(this.API.DELETE_WORK_ORDER_OPERATION, [WorkOrderOperation_Id.toString()]), httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data.success;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('Edit Product Error', []))
      );
  }





  private handleErrorRecord<T>(operation = 'operation', result?: T) {
    return (error: any): any => {

      // TODO: send the error to remote logging infrastructure
      console.log(error.error); // log to console instead

      if (error.error.summary === 'Record Not Found' || error.error.summary === 'Requested API Not Found') {
        return of([]);
      }


      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(error.error as T);
    };
  }

  private handleError(error: HttpErrorResponse) {
    let err;
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      err = 'An error occurred: ' + error.error.message;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      err = error;
    }
    console.log(err);
    // return an observable with a user-facing error message
    // return throwError('Something bad happened; please try again later.');
    return throwError(err);
  }
}
