import { Component } from '@angular/core';
import { TemplateService } from '../../shared/services/template.service';
import { Router, Event, NavigationEnd, ActivatedRoute } from '@angular/router';
import { CheckPermission } from 'src/app/_utils';
import { Permissions } from 'src/app/_enums';

@Component({
    selector: 'app-side-nav',
    templateUrl: './side-nav.component.html',
    styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent {
    isCollapse: boolean;
    isRecieved: any = false;
    navs: any[] = [];
    currentUser: any;

    menu = {
        dashboard                            : true,
        dashboard_team                       : true,
        dashboard_team_performance           : true,
        dashboard_financial_dashboard        : true,
        catogories                           : true,
        materials                            : true,
        products                             : true,
        orders                               : true,
        customers                            : true,
        tax_management                       : true,
        process_management                   : true,
        account_management                   : true,
        account_management_gl_account        : true,
        account_management_bank_account      : true,
        account_management_journal_voucher   : true,
        account_management_accounting_period : true,
        expense_management                   : true,
        expense_management_expense_type      : true,
        expense_management_general_expense   : true,
        purchases                            : true,
        purchases_supplier                   : true,
        purchases_purchase_order             : true,
        purchases_receiving                  : true,
        purchases_billing                    : true,
        purchases_return                     : true,
        purchases_payment                    : true,
        cash_management                      : true,
        cash_management_cash_in_out          : true,
        cash_management_post_date_cheque     : true,
        cash_management_reason               : true,
        shipping                             : true,
        shipping_service_setup               : true,
        shipping_zone_setup                  : true,
        shipping_cities_setup                : true,
        location                             : true,
        location_locations                   : true,
        user                                 : true,
        user_users                           : true,
        user_user_roles                      : true,
        team                                 : true,
        team_teams                           : true,
        work_order                           : true,
        work_order_work_orders               : true,
        work_order_completed_work_order      : true,
        inventory                            : true,
        inventory_stock_adjusment_list       : true,
        reports                              : true,
        reports_inventory_wise_sales         : true,
        reports_logs                         : true,
        reports_ware_house                   : true,
        reports_sale_detail                  : true,
        reports_sale_return                  : true,
        reports_payment_detail               : true,
        reports_order_summary                : true,
        reports_supplier_return              : true,
        reports_supplier_payment             : true,
        reports_purchase_bill                : true,
        reports_expense                      : true,
        reports_vendor                       : true,
        reports_material                     : true,
        reports_consignment_delivery         : true,
        reports_post_date_cheq               : true,
        reports_trial_balance                : true,
        reports_balance_sheet                : true,
        reports_gl_account_report            : true,
        reports_supplier_ledger              : true,
        reports_pnl                          : true,
        reports_customer_ledger              : true,
        reports_pre_order                    : true,
        settings                             : true,
        settings_settings                    : true,
        settings_configure_work_order        : true
    }

    constructor(private tplSvc: TemplateService, private router: Router, private route: ActivatedRoute) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUserZahra'));

        router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                if (window.innerWidth < 992) {
                    this.tplSvc.toggleSideNavCollapse(false);
                }
            }
        });
    }

    ngOnInit() {
        this.setSideMenu()
        this.tplSvc.isSideNavCollapseChanges.subscribe(isCollapse => this.isCollapse = isCollapse);
    }

    setSideMenu(){
        this.menu.dashboard                            = CheckPermission(Permissions.dashboard.name)
        this.menu.dashboard_team                       = CheckPermission(Permissions.dashboard.team.name)
        this.menu.dashboard_team_performance           = CheckPermission(Permissions.dashboard.team_performance.name)
        this.menu.dashboard_financial_dashboard        = CheckPermission(Permissions.dashboard.financial_dashboard.name)
        this.menu.catogories                           = CheckPermission(Permissions.category.name)
        this.menu.materials                            = CheckPermission(Permissions.material.name)
        this.menu.products                             = CheckPermission(Permissions.product.name)
        this.menu.orders                               = CheckPermission(Permissions.order.name)
        this.menu.customers                            = CheckPermission(Permissions.customers.name)
        this.menu.tax_management                       = CheckPermission(Permissions.tax_management.name)
        // this.menu.process_management                   = CheckPermission()
        this.menu.account_management                   = CheckPermission(Permissions.account_management.name)
        this.menu.account_management_gl_account        = CheckPermission(Permissions.account_management.gl_account.name)
        this.menu.account_management_bank_account      = CheckPermission(Permissions.account_management.bank_account.name)
        this.menu.account_management_journal_voucher   = CheckPermission(Permissions.account_management.journal_voucher.name)
        this.menu.account_management_accounting_period = CheckPermission(Permissions.account_management.accounting_period.name)
        this.menu.expense_management                   = CheckPermission(Permissions.expense_management.name)
        this.menu.expense_management_expense_type      = CheckPermission(Permissions.expense_management.expense_type.name)
        this.menu.expense_management_general_expense   = CheckPermission(Permissions.expense_management.general_expense.name)
        this.menu.purchases                            = CheckPermission(Permissions.purchases.name)
        this.menu.purchases_supplier                   = CheckPermission(Permissions.purchases.supplier.name)
        this.menu.purchases_return                     = CheckPermission(Permissions.purchases.returns.name)
        this.menu.purchases_receiving                  = CheckPermission(Permissions.purchases.receiving.name)
        this.menu.purchases_purchase_order             = CheckPermission(Permissions.purchases.purchase_order.name)
        this.menu.purchases_payment                    = CheckPermission(Permissions.purchases.payments.name)
        this.menu.purchases_billing                    = CheckPermission(Permissions.purchases.billing.name)
        this.menu.cash_management                      = CheckPermission(Permissions.cash_management.name)
        this.menu.cash_management_cash_in_out          = CheckPermission(Permissions.cash_management.cash_in_out.name)
        this.menu.cash_management_post_date_cheque     = CheckPermission(Permissions.cash_management.post_date_cheque.name)
        this.menu.cash_management_reason               = CheckPermission(Permissions.cash_management.reasons.name)
        this.menu.shipping                             = CheckPermission(Permissions.shipping.name)
        this.menu.shipping_cities_setup                = CheckPermission(Permissions.shipping.cities_setup.name)
        this.menu.shipping_service_setup               = CheckPermission(Permissions.shipping.service_setup.name)
        this.menu.shipping_zone_setup                  = CheckPermission(Permissions.shipping.zone_setup.name)
        this.menu.location                             = CheckPermission(Permissions.locations.name)
        this.menu.location_locations                   = CheckPermission(Permissions.locations.locations.name)
        this.menu.user                                 = CheckPermission(Permissions.users.name)
        this.menu.user_users                           = CheckPermission(Permissions.users.users.name)
        this.menu.user_user_roles                      = CheckPermission(Permissions.users.user_roles.name)
        this.menu.team                                 = CheckPermission(Permissions.team.name)
        this.menu.team_teams                           = CheckPermission(Permissions.team.teams.name)
        this.menu.work_order                           = CheckPermission(Permissions.work_order.name)
        this.menu.work_order_work_orders               = CheckPermission(Permissions.work_order.work_orders.name)
        this.menu.work_order_completed_work_order      = CheckPermission(Permissions.work_order.completed_work_order.name)
        this.menu.inventory                            = CheckPermission(Permissions.inventory.name)
        this.menu.inventory_stock_adjusment_list       = CheckPermission(Permissions.inventory.stock_adjustment.name)
        this.menu.reports                              = CheckPermission(Permissions.reports.name)
        this.menu.reports_balance_sheet                = CheckPermission(Permissions.reports.balance_sheet.name)
        this.menu.reports_consignment_delivery         = CheckPermission(Permissions.reports.consignment_delivery.name)
        this.menu.reports_customer_ledger              = CheckPermission(Permissions.reports.customer_ledger.name)
        this.menu.reports_expense                      = CheckPermission(Permissions.reports.expense.name)
        this.menu.reports_gl_account_report            = CheckPermission(Permissions.reports.gl_account.name)
        this.menu.reports_inventory_wise_sales         = CheckPermission(Permissions.reports.inventory_wise_sales.name)
        this.menu.reports_logs                         = CheckPermission(Permissions.reports.logs.name)
        this.menu.reports_material                     = CheckPermission(Permissions.reports.material.name)
        this.menu.reports_order_summary                = CheckPermission(Permissions.reports.order_summary.name)
        this.menu.reports_payment_detail               = CheckPermission(Permissions.reports.payment_detail.name)
        this.menu.reports_pnl                          = CheckPermission(Permissions.reports.pnl.name)
        this.menu.reports_post_date_cheq               = CheckPermission(Permissions.reports.post_date_cheque.name)
        this.menu.reports_pre_order                    = CheckPermission(Permissions.reports.pre_order.name)
        this.menu.reports_purchase_bill                = CheckPermission(Permissions.reports.purchase_bill.name)
        this.menu.reports_sale_detail                  = CheckPermission(Permissions.reports.sales_detail.name)
        this.menu.reports_sale_return                  = CheckPermission(Permissions.reports.sales_return.name)
        this.menu.reports_supplier_ledger              = CheckPermission(Permissions.reports.supplier_ledger.name)
        this.menu.reports_supplier_payment             = CheckPermission(Permissions.reports.supplier_payments.name)
        this.menu.reports_supplier_return              = CheckPermission(Permissions.reports.supplier_returns.name)
        this.menu.reports_trial_balance                = CheckPermission(Permissions.reports.trial_balance.name)
        this.menu.reports_vendor                       = CheckPermission(Permissions.reports.vendor.name)
        this.menu.reports_ware_house                   = CheckPermission(Permissions.reports.warehouse.name)
        this.menu.settings                             = CheckPermission(Permissions.settings.name)
        this.menu.settings_configure_work_order        = CheckPermission(Permissions.settings.configure_work_order.name)
        this.menu.settings_settings                    = CheckPermission(Permissions.settings.settings.name)
    }

    toggleSideNavCollapse() {
        this.isCollapse = !this.isCollapse;
        this.tplSvc.toggleSideNavCollapse(this.isCollapse);
    }

    myTeams() {
        let teamId: any;
        if (this.currentUser.Teams && this.currentUser.Teams[0]) {
            teamId = this.currentUser.Teams[0].id;
        }
        this.router.navigate(['/team/members', { team_Id: teamId }]);
    }
}
