import { Injectable } from '@angular/core';
import {
  Resolve, RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { forkJoin, Observable } from 'rxjs';

import { LocationService, UserRoleService, UserService } from '../_services';
import { map, take } from 'rxjs/operators';

@Injectable()
export class UserResolver implements Resolve<any> {

  constructor(private loc: LocationService, private userservice: UserService, private userroleservice: UserRoleService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return forkJoin(
      this.userservice.getUsers(),
      this.userroleservice.getUserRoles(),
      this.loc.getLocations().pipe(
        take(1),
        map((data: any) => {
          if (data) {
            return data;
          } else {
            return null;
          }
        })
      )).pipe(
        map(([users, roles, locations]) => {
          // combineLatest returns an array of values, here we map those values to an object
          return { users, roles, locations };
        })
      );

  }

}
