import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError, of } from 'rxjs';
import { APIConstants } from '../shared/config/api-constant';


@Injectable()
export class ReceivingService {
  private API: any;
  private currentUser: any;
  private accessToken: string;

  constructor(private http: HttpClient, private APIConfig: APIConstants) {
    this.API = APIConfig.get();
    this.currentUser = JSON.parse(localStorage.getItem('currentUserZahra'));
    this.accessToken = `Bearer ${this.currentUser.accessToken}`;
  }

  private getUrl(endpoint: string, params: string[] = []) {
    return this.API.BASE_URL + endpoint + ((params.length > 0) ? '/' + params.join('/') : '');
  }

  createReceiving(Receiving) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http.post<any>(this.getUrl(this.API.CREATE_RECEIVING), Receiving, httpOptions)
  }

  getReceivingById(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http.get<any>(this.getUrl(this.API.GET_RECEIVING)+'/'+id, httpOptions)
  }

  getReceivings() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http.get<any>(this.getUrl(this.API.GET_RECEIVING), httpOptions)
  }

  getReceivingItems() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http.get<any>(this.getUrl(this.API.GET_RECEIVING_ITEMS), httpOptions)
  }

  updateReceiving(id, Receiving) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http.put<any>(this.getUrl(this.API.UPDATE_RECEIVING)+'/'+id, Receiving, httpOptions)
  }

  deleteReceiving(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http.delete<any>(this.getUrl(this.API.GET_RECEIVING)+'/'+id, httpOptions)
  }

}
