import { Component, OnInit, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-enlarge-picture',
  templateUrl: './enlarge-picture.component.html',
  styleUrls: ['./enlarge-picture.component.css']
})
export class EnlargePictureComponent implements OnInit {

  filename: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    console.log('file', this.data);
    this.filename = this.data
  }

  ngOnInit() {
    console.log(this.filename.image)
  }

  onError() {
    $('#productImage').attr('src', 'assets/images/avatars/image.png');
  }
}
