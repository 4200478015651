import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';

import { FinancialDashboardService } from '../_services/financial-dashboard.service'; 

@Component({
  selector: 'app-new-dashboard',
  templateUrl: './new-dashboard.component.html',
  styleUrls: ['./new-dashboard.component.css']
})
export class NewDashboardComponent implements OnInit {

  loader = false

  banks = []
  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  sales
  expenses
  expenseTypes
  expenseAmount
  pnl

  constructor(
    private financialDashboardService : FinancialDashboardService
  ) { }

  async ngOnInit() {
    this.loader = true

    await this.getBankAccounts()

    await this.getSales()

    await this.getExpenses()

    await this.getPNLGraph()

    this.loader = false

    this.barChart()
    this.lineChart()
    // this.lineChart2()
    this.doughnutChart()
    
  }

  getBankAccounts(){
    return new Promise(resolve=>{
      this.financialDashboardService.getBankGraph().subscribe(res=>{
        if(res.success) this.banks = res.data
        resolve(true)
      }, err=>{
        console.log(err);
        resolve(false)
      })
    })
  }

  getSales(){
    return new Promise(resolve=>{
      this.financialDashboardService.getSaleGraph().subscribe(res=>{
        if(res.success) this.sales = res.data
        resolve(true)
      }, err=>{
        console.log(err);
        resolve(false)
      })
    })
  }

  getExpenses(){
    return new Promise(resolve=>{
      this.financialDashboardService.getExpenseGraph().subscribe(res=>{
        if(res.success) {
          this.expenses      = res.data
          this.expenseTypes  = res.data.map(item=>item.name.substring(0,10) + '...')
          this.expenseAmount = res.data.map(item=>item.total_debit)
        }
        resolve(true)
      }, err=>{
        console.log(err);
        resolve(false)
      })
    })
  }

  getPNLGraph(){
    return new Promise(resolve=>{
      this.financialDashboardService.getPNLGraph().subscribe(res=>{
        if(res.success) this.pnl = res.data
        
        resolve(true)
      }, err=>{
        console.log(err);
        resolve(false)
      })
    })
  }

  lineChart() {
    new Chart('linechart', {
      type: 'line',
      data: {
        labels: this.months,
        datasets: [{
          fill: false,
          label: 'months',
          data: [...Object.values(this.sales)],
          borderColor: [
            'rgba(153, 102, 255, 1)',
          ],
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });
  }

  lineChart2() {
    new Chart('linechart2', {
      type: 'line',
      data: {
        labels: this.months,
        datasets: [{
          fill: false,
          label: '# of Votes',
          data: [...Object.values(this.sales)],
          borderColor: [
            'rgba(153, 102, 255, 1)',
          ],
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });
  }

  barChart() {
    new Chart('barchart', {
      type: 'bar',
      data: {
        labels: Object.keys(this.pnl),
        datasets: [{
          fill: false,
          label: '',
          data: [...Object.values(this.pnl)],
          borderColor: [
            'rgba(153, 102, 255, 1)',
          ],
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });
  }

  doughnutChart() {
    new Chart('ctx1', {
      type: 'doughnut',
      data: {
        labels: this.expenseTypes,
        // labels: [
        //   'Red',
        //   'Blue',
        //   'Yellow'
        // ],
        datasets: [{
          label: 'My First Dataset',
          data: this.expenseAmount,
          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)'
          ],
        }],
      },
    });
  }

  getSalesAmount(){
    if(!this.sales) return
    const values = Object.values(this.sales)
    return values[values.length-1]
  }

  getHighestExpense(){
    if(!this.expenseAmount) return
    let max = 0
    this.expenseAmount.forEach(item=>{
      if(item > max) max = item
    })

    return max
  }

}
