import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { SidePanelComponent } from './side-panel/side-panel.component';
import { FooterComponent } from './footer/footer.component';
import { Sidebar_Directives } from '../shared/directives/side-nav.directive';
import {NgSelectizeModule} from 'ng-selectize';
import {UserNotificationResolver, WorkOrderResolver} from '../_guards';
import {
  ProductService,
  MaterialService,
  CategoryService,
  WorkOrderService,
  WorkOrderConfigService,
  UserNotificationService
} from '../_services';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
  imports: [
    SharedModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectizeModule
  ],
    exports: [
        HeaderComponent,
        SideNavComponent,
        SidePanelComponent,
        FooterComponent,
        Sidebar_Directives
    ],
    declarations: [
        HeaderComponent,
        SideNavComponent,
        SidePanelComponent,
        FooterComponent,
        Sidebar_Directives
    ],
    providers: [
      WorkOrderResolver,
      ProductService,
      MaterialService,
      CategoryService,
      WorkOrderService,
      WorkOrderConfigService,
      UserNotificationResolver,
      UserNotificationService
    ]
})


export class TemplateModule { }
