import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthenticationService, PermissionService } from '../../_services';
import { Permissions } from 'src/app/_enums';

@Component({
  templateUrl: 'sign-in.html',
  providers: [AuthenticationService]
})

export class SignInComponent {

  error: boolean = false;
  showSpinner: boolean = false;

  constructor(
    private authenticationService : AuthenticationService,
    private permissionService     : PermissionService,
    private router                : Router) {
    //
  }


  LoginUser() {
    let userEmail = (<HTMLInputElement>document.getElementById('userEmail')).value;
    let userPassword = (<HTMLInputElement>document.getElementById('userPassword')).value;
    //
    if (userEmail != '') {
      if (userPassword != '') {
        this.authenticationService.login(userEmail, userPassword)
          .subscribe(
            async (data: any) => {
              const permision = await this.getUserPermission(data)
              let allowed
              if(permision) allowed = permision['modules'].find(item=> item.allowed === true)
              this.showSpinner = true;

              if (data) {
                this.router.navigate([allowed ? Permissions[allowed.description].url : '/product/products']);
              }

            },
            (error: any) => {
              /*this.alertService.error(error);*/
              this.showSpinner = false;
              this.error = true;
              console.log(error);

            });
      } else {
        alert('Enter Password');
      }
    } else {
      alert('Enter Valid Email Address');
    }
  }

  getUserPermission(user){
    return new Promise(resolve=>{
      this.permissionService.getUserPermission(user.id).subscribe(res=>{
        console.log(res);
        if(res.data) localStorage.setItem('permissions', JSON.stringify(res.data))

        resolve(res.data)
      }, err=>{
        console.log(err);
        resolve(false)
      })
    })
  }


}
