import { Injectable } from '@angular/core';
import {
  Resolve, RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { map, take } from 'rxjs/operators'

import { LotService, ProductService, TeamService, WorkOrderConfigService, WorkOrderService, LocationService } from '../_services';

@Injectable()
export class LotResolver implements Resolve<any> {

  constructor(private productservice: ProductService,
    private locationService: LocationService,
    private workorderservice: WorkOrderService,
    private lotservice: LotService,
    private workorderconfigservice: WorkOrderConfigService,
    private teamservice: TeamService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return forkJoin(
      this.locationService.getLocations(),
      this.productservice.getProduct(),
      this.workorderservice.getWorkOrderList(),
      this.lotservice.getLotList(),
      this.lotservice.getStockAdjustmentList(),
      this.workorderconfigservice.getWorkOrderOperations(),
      this.teamservice.getTeam()
        .pipe(
          take(1),
          map((data: any) => {
            if (data) {
              return data;
            } else {
              return null;
            }
          })
        )).pipe(
          map(([locations, products, workorders, lots, stockadjustments, workorderoperations, teams]) => {
            // combineLatest returns an array of values, here we map those values to an object
            return { locations, products, workorders, lots, stockadjustments, workorderoperations, teams };
          })
        );
  }

}
