
function CheckPermission(module_name){

    let permission = localStorage.getItem('permissions')
    if(!permission) return true

    permission = JSON.parse(permission)

    const FindModule = (arr) => {
        const raw = arr.map(item=>{
            if(item.description === module_name) return item
            if(item.Modules) return FindModule(item.Modules)
        })
        return raw.find(item=>item)
    }
    let module = FindModule(permission['modules'])

    if(module) return module.allowed

    return true

}

export {CheckPermission}