import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class APIConstants {

  private apiConfig: any;

  constructor() {

    this.apiConfig = {
      env: 'live',
      local: {
        BASE_URL: 'http://127.0.0.1:3000/api',
        BASE_IMAGE_URL: 'http://127.0.0.1:3000',
        BEARER_TOKEN: 'bearer e33c884571755fe2ffbe2fc09219b8454dc3f13c'
      },
      ismail: {
        BASE_URL: 'http://192.168.0.106:3000/api',
        BASE_IMAGE_URL: 'http://192.168.0.106:3000',
        BEARER_TOKEN: 'bearer e33c884571755fe2ffbe2fc09219b8454dc3f13c'
      },
      ahmed: {
        BASE_URL: 'http://192.168.18.49:3000/api',
        BASE_IMAGE_URL: 'http://192.168.18.49:3000',
        BEARER_TOKEN: 'bearer e33c884571755fe2ffbe2fc09219b8454dc3f13c'
      },
      zubair: {
        BASE_URL: 'http://192.168.100.17:3000/api',
        BASE_IMAGE_URL: 'http://192.168.100.17:3000',
        BEARER_TOKEN: 'bearer e33c884571755fe2ffbe2fc09219b8454dc3f13c'
      },
      zia: {
        // BASE_URL: 'http://192.168.18.61:3000/api',
        BASE_URL: 'http://localhost:3000/api',
        BASE_IMAGE_URL: 'http://192.168.1.105:3000',
        BEARER_TOKEN: 'bearer e33c884571755fe2ffbe2fc09219b8454dc3f13c'
      },
      alpha: {
        BASE_URL: 'https://alpha.inventory.zahraebrahim.com/api',
        BASE_IMAGE_URL: 'https://alpha.inventory.zahraebrahim.com/',
        BEARER_TOKEN: 'bearer e33c884571755fe2ffbe2fc09219b8454dc3f13c'
      },
      beta: {
        BASE_URL: 'https://staging.inventory.zahraebrahim.com/api',
        // BASE_URL: 'http://13.250.55.127:3000/api',
        BASE_IMAGE_URL: 'https://staging.inventory.zahraebrahim.com/',
        BEARER_TOKEN: 'bearer e33c884571755fe2ffbe2fc09219b8454dc3f13c'
      },
      live: {
        BASE_URL: 'https://inventory.zahraebrahim.com/api',
        // BASE_URL: 'http://13.250.55.127:3000/api',
        BASE_IMAGE_URL: 'https://inventory.zahraebrahim.com/',
        BEARER_TOKEN: 'bearer e33c884571755fe2ffbe2fc09219b8454dc3f13c'
      },
      development: {
        BASE_URL: 'http://192.168.1.113:3000/api',
        BASE_IMAGE_URL: 'http://192.168.100.21:3000',
        BEARER_TOKEN: 'bearer e33c884571755fe2ffbe2fc09219b8454dc3f13c'
      },
      production: {
        BASE_URL: 'http://13.229.236.204:3000/api',
        BEARER_TOKEN: 'bearer e33c884571755fe2ffbe2fc09219b8454dc3f13c'
      },
      QA: {
        BASE_URL: 'http://3.0.4.208:3005/api',
        BEARER_TOKEN: 'bearer e33c884571755fe2ffbe2fc09219b8454dc3f13c'
      },
      stagging: {
        BASE_URL: 'http://3.0.4.208:3006/api',
        BEARER_TOKEN: 'bearer e33c884571755fe2ffbe2fc09219b8454dc3f13c'
      },
      endpoints: {
        AUTHENTICATE                        : '/oauth/login',
        GET_LOCATIONS                       : '/location/list',
        GET_LOCATION                        : '/location',
        CREATE_LOCATION                     : '/location/',
        EDIT_LOCATION                       : '/location',
        GET_PRODUCTS                        : '/product/list',
        GET_PRODUCT                         : '/product',
        CREATE_PRODUCT                      : '/product/',
        EDIT_PRODUCT                        : '/product',
        DELETE_PRODUCT                      : '/product/',
        UPLOAD_MULTIPLE_IMAGE               : '/product/pimages',
        UPLOAD_DOCUMENT_PRODUCT             : '/product/uploadDocument',
        GET_CUSTOMER                        : '/customer/list',
        GET_CUSTOMER_ORDERS                 : '/order/list-customer-order',
        GET_ORDER                           : '/order/list',
        CREATE_PAYMENT_JOURNAL_BATCH        : '/order/gl/customer-pay',
        ORDER_GET_STOCK                     : '/dashboard/stock',
        CREATE_ORDER                        : '/order',
        EDIT_ORDER                          : '/order',
        DELETE_ORDER                        : '/order',
        GET_MATERIAL                        : '/material/list',
        CREATE_MATERIAL                     : '/material/',
        EDIT_MATERIAL                       : '/material',
        DELETE_MATERIAL                     : '/material/',
        GET_CATEGORY                        : '/category/list',
        GET_CATEGORY_SINGLE                 : '/category/',
        GET_CATEGORY_PRODUCT                : '/category/product/list',
        ADD_CATEGORY_PRODUCT                : '/category/products/sorting',
        CREATE_CATEGORY                     : '/category/',
        EDIT_CATEGORY                       : '/category',
        DELETE_CATEGORY                     : '/category/',
        GET_TEAM                            : '/team/list',
        GET_SINGLE_TEAM                     : '/team',
        CREATE_TEAM                         : '/team/',
        EDIT_TEAM                           : '/team',
        DELETE_TEAM                         : '/team/',
        GET_USER                            : '/user/list_users/0',
        GET_USER_SINGLE                     : '/user/users',
        CREATE_USER                         : '/user/users/register',
        EDIT_USER                           : '/user/users',
        EDIT_USER_PASSWORD                  : '/user/users/reset_password',
        CHANGE_USER_PASSWORD                : '/user/users/change_password',
        CHANGE_ADMIN_PASSWORD               : '/user/admin/change_password',
        DELETE_USER                         : '/user/',
        GET_USER_ROLES                      : '/user_roles/list',
        GET_USER_ROLE                       : '/user_roles/',
        GET_WORK_ORDER_OPERATIONS           : '/workorder_config/wooperation/list',
        GET_OPERATIONS                      : '/workorder_config/operation/list',
        GET_WORK_ORDER_TYPE                 : '/workorder_config/type/list',
        GET_WORK_ORDER_DETAILS              : '/workorder/workorderdetails',
        GET_WORK_ORDER_OPERATION            : '/workorder/workorderoperation',
        CREATE_WORK_ORDER_TYPE              : '/workorder_config/type',
        EDIT_WORK_ORDER_TYPE                : '/workorder_config/type',
        CREATE_WORK_ORDER_OPERATION         : '/workorder_config/wooperation',
        EDIT_WORK_ORDER_OPERATION           : '/workorder_config/wooperation',
        DELETE_WORK_ORDER_OPERATION         : '/workorder_config/wooperation/delete',
        CREATE_OPERATION                    : '/workorder_config/operation',
        EDIT_OPERATION                      : '/workorder_config/operation',
        DELETE_OPERATION                    : '/workorder_config/operation/delete',
        GET_WORK_ORDERS_LIST                : '/workorder/list',
        EDIT_WORK_ORDERS                    : '/workorder/',
        CREATE_WORK_ORDERS                  : '/workorder/',
        EDIT_WORK_ORDER_STATUS              : '/workorder/',
        DELETE_WORK_ORDER                   : '/workorder/',
        GET_WORK_ORDER                      : '/workorder/',
        GET_WORK_ORDERS_OPERATIONS          : '/workorder/operation/view',
        GET_LOT                             : '/lot/list',
        GET_LOT_SINGLE                      : '/lot/',
        CREATE_LOT                          : '/lot/',
        CREATE_ADJUSTMENT_LOT               : '/stockadjustment/create',
        STOCK_ADJUSTMENT_LIST               : '/stockadjustment/list',
        DELETE_LOT                          : '/lot/',
        EDIT_LOT                            : '/lot/',
        CREATE_WORK_ORDER_TRANSACTIONS      : '/workorder/transaction/create',
        FETCH_LOT_OUT_DETAILS               : '/lot/fetch/product',
        DASHBOARD_GET_WORK_ORDER_ASSIGNEMTS : '/dashboard/workorder/assignments',
        DASHBOARD_GET_TEAM_ASSIGNEMTS       : '/dashboard/team/assignments',
        DASHBOARD_GET_PRODUCT_ASSIGNEMTS    : '/dashboard/product/assignments',
        DASHBOARD_GET_STOCK                 : '/dashboard/stock',
        DASHBOARD_GET_WORKORDERS            : '/dashboard/workorder',
        NOTIFICATIONS_GET_LIST              : '/notification/list',
        GET_SETTINGS                        : '/setting',
        EDIT_SETTINGS                       : '/setting/update',
        EDIT_PRODUCT_STATUS                 : '/product/status/',
        GET_REPORTS                         : '/deletelog/list',
        WORK_ORDER_BY_OPERATIONS            : '/dashboard/work_order_by_operations',
        WORK_ORDER_BY_TEAM                  : '/dashboard/work_order_by_team',
        WORK_ORDER_BY_TEAM_LIST             : '/dashboard/work_order_by_team_list',
        INVENTORY_WORK_ORDER_BY_CLIENT      : '/dashboard/work_order_inventory_by_client',
        INVENTORY_WORK_ORDER_BY_CLIENT_LIST : '/dashboard/work_order_inventory_by_client_list/',
        INVENTORY_WORK_ORDER_BY_FILTER      : '/dashboard/work_order_inventory_by_type/',
        ADD_SUPPLIER                        : '/purchases/supplier',
        GET_SUPPLIERS                       : '/purchases/supplier',
        GET_GL_ACCOUNTS_HEAD                : '/glAccountHead',
        CREATE_GL_ACCOUNT                   : '/glAccount',
        GET_GL_ACCOUNTS                     : '/glAccount',
        GET_UOMS                            : '/uom',
        CREATE_PURCHASE_ORDER               : '/purchaseOrder/create',
        UPDATE_PURCHASE_ORDER               : '/purchaseOrder/update',
        GET_PURCHASE_ORDER                  : '/purchaseOrder',
        CREATE_BANK_ACCOUNT                 : '/bankAccount/create',
        GET_BANK_ACCOUNT                    : '/bankAccount',
        CREATE_JOURNAL_VOUCHER              : '/journalVoucher/create',
        GET_JOURNAL_VOUCHER                 : '/journalVoucher',
        CREATE_EXPENSE                      : '/expense/create',
        ADD_CUSTOMER                        : '/customer/register',
        GET_EXPENSE                         : '/expense',
        CREATE_GENERAL_EXPENSE              : '/generalExpense/create',
        GET_GENERAL_EXPENSE                 : '/generalExpense',
        CREATE_RECEIVING                    : '/receiving/create',
        GET_RECEIVING                       : '/receiving',
        GET_RECEIVING_ITEMS                 : '/receiving/items',
        UPDATE_RECEIVING                    : '/receiving/update',
        GET_BILL                            : '/bill',
        CREATE_BILL                         : '/bill/create',
        UPDATE_BILL                         : '/bill/update',
        CREATE_PURCHASE_RETURN              : '/purchaseReturn/create',
        GET_PURCHASE_RETURN                 : '/purchaseReturn',
        UPDATE_PURCHASE_RETURN              : '/purchaseReturn/update',
        CREATE_PAYMENT                      : '/payment/create',
        GET_PAYMENT                         : '/payment',
        UPDATE_PAYMENT                      : '/payment/update',
        CREATE_PROCESS_MANAGEMENT           : '/processManagement/create',
        GET_PROCESS_MANAGEMENT              : '/processManagement',
        UPDATE_PROCESS_MANAGEMENT           : '/processManagement/update',
        GET_CHEQUES                         : '/cheque',
        GET_JOURNAL_BATCH                   : '/journalBatch',
        CREATE_ACCOUNTING_PERIOD            : '/accountingPeriod/create',
        GET_ACCOUNTING_PERIOD               : '/accountingPeriod',
        GET_MATERIAL_TYPES                  : '/materialTypes',
        GET_BANK_ACCOUNT_DASHBOARD          : '/finance/dashboard/bank',
        GET_SALES_DASHBOARD                 : '/finance/dashboard/sales',
        GET_EXPENSE_DASHBOARD               : '/finance/dashboard/expense',
        GET_PNL_DASHBOARD                   : '/finance/dashboard/pnl',
        GET_MODULE                          : '/module',
        GET_PERMISSION                      : '/permission'
      }
    };
  }

  get(env: string = this.apiConfig.env) {
    return { ...this.apiConfig[env], ...this.apiConfig.endpoints };
  }

  getUrl(endpoint: string, params: string[] = []) {
    return this.apiConfig[this.apiConfig.env].BASE_URL + endpoint + ((params.length > 0) ? '/' + params.join('/') : '');
  }

}
