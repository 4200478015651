import {Injectable} from '@angular/core';
import {
  Resolve, RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';



import { MaterialService } from '../_services';

@Injectable()
export class MaterialResolver implements Resolve<any> {

  constructor(private materialservice: MaterialService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

    return this.materialservice.getMaterial();

  }

}
