import { Injectable } from '@angular/core';
import {
  Resolve, RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { forkJoin, Observable } from 'rxjs';

import { TeamService, UserService, WorkOrderConfigService } from '../_services';
import { map, take } from 'rxjs/operators';

@Injectable()
export class TeamResolver implements Resolve<any> {

  constructor(private teamservice: TeamService, private workorderconfigservice: WorkOrderConfigService, private userservice: UserService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return forkJoin(
      this.teamservice.getTeam(),
      this.workorderconfigservice.getOperations(),
      this.userservice.getUsers()
        .pipe(
          take(1),
          map((data: any) => {
            if (data) {
              return data;
            } else {
              return null;
            }
          })
        )).pipe(
          map(([teams, operations, users]) => {
            // combineLatest returns an array of values, here we map those values to an object
            return { teams, operations, users };
          })
        );

  }

}
