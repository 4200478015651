import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {throwError, of} from 'rxjs';
import {APIConstants} from '../shared/config/api-constant';

@Injectable()
export class WorkOrderService {

  private API: any;
  private currentUser: any;
  private accessToken: string;

  constructor(private http: HttpClient, private APIConfig: APIConstants) {
  this.API = APIConfig.get();
  this.currentUser = JSON.parse(localStorage.getItem('currentUserZahra'));
  this.accessToken = `Bearer ${this.currentUser.accessToken}`;


}

  private getUrl(endpoint: string, params: string[] = []) {
    return this.API.BASE_URL + endpoint + ((params.length > 0) ? '/' + params.join('/') : '');
  }
  private getUrlWOTimeStamp(endpoint: string, params: string[] = []) {
    return this.API.BASE_URL + endpoint + ((params.length > 0) ? '/' + params.join('/') : '');
  }
  private getPostUrl(endpoint: string, params: string[] = []) {
    return this.API.BASE_URL + endpoint + ((params.length > 0) ? '/' + params.join('/') : '');
  }


  getWorkOrderCompletedList(offset?:any) {

  const httpOptions = {
    headers: new HttpHeaders({
      'Authorization': this.accessToken
    })
  };


  return this.http
    .get(this.getUrlWOTimeStamp(this.API.GET_WORK_ORDERS_LIST + "?is_active=1&" + "status="+offset), httpOptions)
    .pipe(
      map((data: any) => {
        if (data.success) {
          return data.data;
        }
        return data;
      })
    ).pipe(
      catchError(this.handleErrorRecord('No Work Order Found', []))
    );
}
  getWorkOrderList() {

  let user;
  if(localStorage.getItem('currentUserZahra')){
      user = JSON.parse(localStorage.getItem('currentUserZahra'));
  }
  const httpOptions = {
    headers: new HttpHeaders({
      'Authorization': `Bearer ${user.accessToken}`
    })
  };

  return this.http
    .get(this.getUrl(this.API.GET_WORK_ORDERS_LIST), httpOptions)
    .pipe(
      map((data: any) => {
        if (data.success) {
          return data.data;
        }
        return data;
      })
    ).pipe(
      catchError(this.handleErrorRecord('No Work Order Found', []))
    );
}
  getWorkOrderOperationList(operation_id:any) {

    let OperationId = operation_id ? operation_id : 1;

  const httpOptions = {
    headers: new HttpHeaders({
      'Authorization': this.accessToken
    })
  };


  return this.http
    .get(this.getUrl(this.API.GET_WORK_ORDERS_OPERATIONS,[OperationId.toString()]), httpOptions)
    .pipe(
      map((data: any) => {
        if (data.success) {
          return data.data;
        }
        return data;
      })
    ).pipe(
      catchError(this.handleErrorRecord('No Work Order Operations Found', []))
    );
}
  getWorkOrder(work_order_Id:any) {

    let workorderId = work_order_Id ? work_order_Id : 1;

  const httpOptions = {
    headers: new HttpHeaders({
      'Authorization': this.accessToken
    })
  };


  return this.http
    .get(this.getUrl(this.API.GET_WORK_ORDERS_OPERATIONS,[workorderId.toString()]), httpOptions)
    .pipe(
      map((data: any) => {
        if (data.success) {
          return data.data;
        }
        return data;
      })
    ).pipe(
      catchError(this.handleErrorRecord('No Work Order Operations Found', []))
    );
}
  editWorkOrder(work_order_Id:any,work_order:any) {



   const httpOptions = {
    headers: new HttpHeaders({
      'Authorization': this.accessToken
    })
  };


  return this.http
    .put(this.getUrl(this.API.GET_WORK_ORDER,[work_order_Id.toString()]),work_order, httpOptions)
    .pipe(
      map((data: any) => {
        if (data.success) {
          return true;
        }
        return false;
      })
    ).pipe(
      catchError(this.handleErrorRecord('No Work Order Operations Found', []))
    );
};
  addWorkOrder(work_order:any) {

   const httpOptions = {
    headers: new HttpHeaders({
      'Authorization': this.accessToken
    })
  };


  return this.http
    .post<any>(this.getUrl(this.API.CREATE_WORK_ORDERS),work_order, httpOptions)
    .pipe(
      map((data: any) => {
        if (data.success) {
          return data.data;
        }
        return data;
      })
    ).pipe(
      catchError(this.handleErrorRecord('No Work Order Operations Found', []))
    );
};
  createWorkOrderTransactions(workorder:any){
    //CREATE_WORK_ORDER_TRANSACTIONS
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };


    return this.http
      .post<any>(this.getUrl(this.API.CREATE_WORK_ORDER_TRANSACTIONS),workorder, httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('No Work Order Operations Found', []))
      );
  }


  deleteWorkOrderByStatus(workOrderId,workOrderObj: any){
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken,
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<any>(this.getPostUrl(this.API.EDIT_WORK_ORDER_STATUS + workOrderId + '/status'),JSON.stringify(workOrderObj), httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return true;
          }
          return false;
        })
      ).pipe(
        catchError(this.handleErrorRecord('No Work Order Operations Found', []))
      );
  }



  private handleErrorRecord<T>(operation = 'operation', result?: T) {
  return (error: any): any => {

    // TODO: send the error to remote logging infrastructure
    console.log(error.error); // log to console instead

    if (error.error.summary === 'Record Not Found' || error.error.summary === 'Requested API Not Found') {
      return of([]);
    }


    // TODO: better job of transforming error for user consumption
    console.log(`${operation} failed: ${error.message}`);

    // Let the app keep running by returning an empty result.
    return of(error.error as T);
  };
}

private handleError(error: HttpErrorResponse) {
  let err;
  if (error.error instanceof ErrorEvent) {
    // A client-side or network error occurred. Handle it accordingly.
    console.error('An error occurred:', error.error.message);
    err = 'An error occurred: ' + error.error.message;
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong,
    console.error(
      `Backend returned code ${error.status}, ` +
      `body was: ${error.error}`);
    err = error;
  }
  console.log(err);
  // return an observable with a user-facing error message
  // return throwError('Something bad happened; please try again later.');
  return throwError(err);
}
}
