import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError, of } from 'rxjs';
import { APIConstants } from '../shared/config/api-constant';


@Injectable()
export class ProcessManagementService {
  private API: any;
  private currentUser: any;
  private accessToken: string;

  constructor(private http: HttpClient, private APIConfig: APIConstants) {
    this.API = APIConfig.get();
    this.currentUser = JSON.parse(localStorage.getItem('currentUserZahra'));
    this.accessToken = `Bearer ${this.currentUser.accessToken}`;
  }

  private getUrl(endpoint: string, params: string[] = []) {
    return this.API.BASE_URL + endpoint + ((params.length > 0) ? '/' + params.join('/') : '');
  }

  createProcessManagement(PurchaseOrder) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http.post<any>(this.getUrl(this.API.CREATE_PROCESS_MANAGEMENT), PurchaseOrder, httpOptions)
  }

  getProcessManagementById(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http.get<any>(this.getUrl(this.API.GET_PROCESS_MANAGEMENT)+'/'+id, httpOptions)
  }

  getProcessManagements() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http.get<any>(this.getUrl(this.API.GET_PROCESS_MANAGEMENT), httpOptions)
  }

  // getLastPO() {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Authorization': this.accessToken
  //     })
  //   };

  //   return this.http.get<any>(this.getUrl(this.API.GET_PURCHASE_ORDER)+'/lastPO', httpOptions)
  // }

  updateProcessManagement(id, PurchaseOrder) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http.put<any>(this.getUrl(this.API.UPDATE_PROCESS_MANAGEMENT)+'/'+id, PurchaseOrder, httpOptions)
  }

  deleteProcessManagement(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http.delete<any>(this.getUrl(this.API.GET_PROCESS_MANAGEMENT)+'/'+id, httpOptions)
  }

}
