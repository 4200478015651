import { Injectable } from '@angular/core';
import {
  Resolve, RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { map, take } from 'rxjs/operators'
import { OrderService, } from '../_services';
import { ShippingService } from '../_services/shipping.service';

@Injectable()
export class OrderResolver implements Resolve<any> {

  constructor(private orderservice: OrderService, private shippingservice: ShippingService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return forkJoin(
      this.orderservice.getOrder(),
      this.orderservice.getStockList(),
      this.shippingservice.getShipping()).pipe(
        map(([orders, stocks, shippingservice]) => {
          // combineLatest returns an array of values, here we map those values to an object
          return { orders, stocks, shippingservice };
        })
      );
  }
}

