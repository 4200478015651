import {Injectable} from '@angular/core';
import {
  Resolve, RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';



import {MaterialService, UserRoleService} from '../_services';

@Injectable()
export class UserRolesResolver implements Resolve<any> {

  constructor(private userrolesservice: UserRoleService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

    return this.userrolesservice.getUserRoles();

  }

}
