import {Injectable} from '@angular/core';
import {
  Resolve, RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import {map, take} from 'rxjs/operators'



import { WorkOrderConfigService } from '../_services';

@Injectable()
export class  WorkOrderConfigResolver implements Resolve<any> {

  constructor(private workorderconfigservice: WorkOrderConfigService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return forkJoin(
      this.workorderconfigservice.getWorkOrderOperations(),
      this.workorderconfigservice.getOperations(),
      this.workorderconfigservice.getWorkOrderOperationsType().pipe(
        take(1),
        map((data: any) => {
          if(data){
            return data;
          }else{
            return null;
          }
        })
      )).pipe(
      map(([workorderoperations, operations, workorderoperationtype]) => {
        // combineLatest returns an array of values, here we map those values to an object
        return { workorderoperations, operations, workorderoperationtype};
      })
    );

  }

}
