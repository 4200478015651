import { Injectable } from '@angular/core';
import {
  Resolve, RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { map, take } from 'rxjs/operators'

import { LotService, ProductService, TeamService, WorkOrderConfigService, WorkOrderService, LocationService } from '../_services';

@Injectable()
export class StockAdjustmentResolver implements Resolve<any> {

  constructor(
    private lotservice: LotService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return forkJoin(
      this.lotservice.getStockAdjustmentList()
        .pipe(
          take(1),
          map((data: any) => {
            if (data) {
              return data;
            } else {
              return null;
            }
          })
        )).pipe(
          map(([ stockadjustments,]) => {
            // combineLatest returns an array of values, here we map those values to an object
            return {  stockadjustments, };
          })
        );
  }

}
