const Permissions = {
    dashboard: {
        name : 'dashboard',
        url : '/dashboard',
        team : {
            name: 'dashboard_team'
        },
        team_performance : {
            name: 'dashboard_team_performance'
        },
        financial_dashboard : {
            name: 'dashboard_financial_dashboard'
        }
    },
    category: {
        name : 'category',
        url: '/category/listing',
        list: {
            name: 'category_list'
        },
        create: {
            name: 'category_create'
        },
        update: {
            name: 'category_update'
        }
    },
    material: {
        name: 'material',
        url: '/material/materialListing',
        list: {
            name: 'material_list'
        },
        create: {
            name: 'material_create'
        },
        update: {
            name: 'material_update'
        },
        delete: {
            name: 'material_delete'
        }
    },
    product: {
        name: 'product',
        url: '/product/products',
        list: {
            name: 'product_list'
        },
        create: {
            name: 'product_create'
        },
        update: {
            name: 'product_update'
        },
        delete: {
            name: 'product_delete'
        }
    },
    order: {
        name: 'order',
        url:'/order/listing',
        list: {
            name: 'order_list'
        },
        update: {
            name: 'order_update'
        },
    },
    customers: {
        name: 'customers',
        url:'/customer/listing',
        list: {
            name: 'customers_list'
        },
    },
    tax_management: {
        name: 'tax_management',
        url:'/tax-management/listing',
        list: {
            name: 'tax_management_list'
        },
        create: {
            name: 'tax_management_create'
        },
        update: {
            name: 'tax_management_update'
        },
        delete: {
            name: 'tax_management_delete'
        }
    },
    account_management: {
        name: 'account_management',
        url: '/account-management',
        gl_account: {
            name: 'account_management_gl_account',
            list: {
                name: 'account_management_gl_account_list'
            },
            create: {
                name: 'account_management_gl_account_create'
            },
            update: {
                name: 'account_management_gl_account_update'
            },
            delete: {
                name: 'account_management_gl_account_delete'
            }
        },
        bank_account: {
            name: 'account_management_bank_account',
            list: {
                name: 'account_management_bank_account_list'
            },
            create: {
                name: 'account_management_bank_account_create'
            },
            update: {
                name: 'account_management_bank_account_update'
            },
            delete: {
                name: 'account_management_bank_account_delete'
            }
        },
        journal_voucher: {
            name: 'account_management_journal_voucher',
            list: {
                name: 'account_management_journal_voucher_list'
            },
            create: {
                name: 'account_management_journal_voucher_create'
            },
            update: {
                name: 'account_management_journal_voucher_update'
            },
            delete: {
                name: 'account_management_journal_voucher_delete'
            }
        },
        accounting_period : {
            name: 'account_management_accounting_period',
            list: {
                name: 'account_management_accounting_period_list'
            },
            create: {
                name: 'account_management_accounting_period_create'
            },
            update: {
                name: 'account_management_accounting_period_update'
            },
            delete: {
                name: 'account_management_accounting_period_delete'
            }
        }
    },
    expense_management: {
        name: 'expense_management',
        url: '/expense-management',
        expense_type: {
            name: 'expense_management_expense_type',
            list: {
                name: 'expense_management_expense_type_list'
            },
            create: {
                name: 'expense_management_expense_type_create'
            },
            update: {
                name: 'expense_management_expense_type_update'
            },
            delete: {
                name: 'expense_management_expense_type_delete'
            }
        },
        general_expense: {
            name: 'expense_management_general_expense',
            list: {
                name: 'expense_management_general_expense_list'
            },
            create: {
                name: 'expense_management_general_expense_create'
            },
            update: {
                name: 'expense_management_general_expense_update'
            },
            delete: {
                name: 'expense_management_general_expense_delete'
            }
        }
    },
    purchases: {
        name: 'purchases',
        url: '/supplier',
        supplier:{
            name: 'purchases_supplier',
            list: {
                name: 'purchases_supplier_list'
            },
            create: {
                name: 'purchases_supplier_create'
            },
            update: {
                name: 'purchases_supplier_update'
            },
            delete: {
                name: 'purchases_supplier_delete'
            }
        },
        purchase_order: {
            name: 'purchases_purchase_order',
            list: {
                name: 'purchases_purchase_order_list'
            },
            create: {
                name: 'purchases_purchase_order_create'
            },
            update: {
                name: 'purchases_purchase_order_update'
            },
            delete: {
                name: 'purchases_purchase_order_delete'
            }
        },
        receiving: {
            name: 'purchases_receiving',
            list: {
                name: 'purchases_receiving_list'
            },
            create: {
                name: 'purchases_receiving_create'
            },
            update: {
                name: 'purchases_receiving_update'
            },
            delete: {
                name: 'purchases_receiving_delete'
            }
        },
        billing: {
            name: 'purchases_billing',
            list: {
                name: 'purchases_billing_list'
            },
            create: {
                name: 'purchases_billing_create'
            },
            update: {
                name: 'purchases_billing_update'
            },
            delete: {
                name: 'purchases_billing_delete'
            }
        },
        returns: {
            name: 'purchases_return',
            list: {
                name: 'purchases_return_list'
            },
            create: {
                name: 'purchases_return_create'
            },
            update: {
                name: 'purchases_return_update'
            },
            delete: {
                name: 'purchases_return_delete'
            }
        },
        payments: {
            name: 'purchases_payments',
            list: {
                name: 'purchases_payments_list'
            },
            create: {
                name: 'purchases_payments_create'
            },
            update: {
                name: 'purchases_payments_update'
            },
            delete: {
                name: 'purchases_payments_delete'
            }
        }, 
    },
    cash_management: {
        name: 'cash_management',
        url: '/cash-management',
        cash_in_out: {
            name: 'cash_management_cash_in_out',
            list: {
                name: 'cash_management_cash_in_out_list'
            },
            update: {
                name: 'cash_management_cash_in_out_update'
            },
        },
        post_date_cheque: {
            name: 'cash_management_post_date_cheque',
            list: {
                name: 'cash_management_post_date_cheque_list'
            },
            update: {
                name: 'cash_management_post_date_cheque_update'
            },
        },
        reasons: {
            name: 'cash_management_reasons',
            list: {
                name: 'cash_management_reasons_list'
            },
            create: {
                name: 'cash_management_reasons_create'
            },
            update: {
                name: 'cash_management_reasons_update'
            },
            delete: {
                name: 'cash_management_reasons_delete'
            }
        },
    },
    shipping: {
        name: 'shipping',
        url: '/shipping',
        service_setup: {
            name: 'shipping_service_setup',
            list: {
                name: 'shipping_service_setup_list'
            },
            create: {
                name: 'shipping_service_setup_create'
            },
            update: {
                name: 'shipping_service_setup_update'
            },
            delete: {
                name: 'shipping_service_setup_delete'
            }
        },
        zone_setup: {
            name: 'shipping_zone_setup',
            list: {
                name: 'shipping_zone_setup_list'
            },
            create: {
                name: 'shipping_zone_setup_create'
            },
            update: {
                name: 'shipping_zone_setup_update'
            },
            delete: {
                name: 'shipping_zone_setup_delete'
            }
        },
        cities_setup: {
            name: 'shipping_cities_setup',
            list: {
                name: 'shipping_cities_setup_list'
            },
            create: {
                name: 'shipping_cities_setup_create'
            },
            update: {
                name: 'shipping_cities_setup_update'
            },
            delete: {
                name: 'shipping_cities_setup_delete'
            }
        },
    },
    locations: {
        name: 'locations',
        url: '/location',
        locations: {
            name: 'locations_locations',
            list: {
                name: 'locations_locations_list'
            },
            create: {
                name: 'locations_locations_create'
            },
            update: {
                name: 'locations_locations_update'
            },
        },

    },
    users: {
        name: 'users',
        url: '/user/list',
        users: {
            name: 'users_users',
            list: {
                name: 'users_users_list'
            },
            create: {
                name: 'users_users_create'
            },
            update: {
                name: 'users_users_update'
            },
        },
        user_roles: {
            name: 'users_user_roles',
            list: {
                name: 'users_user_roles_list'
            },
        }
    },
    team: {
        name: 'team',
        url: '/team/list',
        teams: {
            name: 'team_teams',
            list: {
                name: 'team_teams_list'
            },
            create: {
                name: 'team_teams_create'
            },
            update: {
                name: 'team_teams_update'
            },
        }
    },
    work_order: {
        name: 'work_order',
        url: '/workOrder/listingWorkOrder',
        work_orders:{
            name: 'work_order_work_order',
            list: {
                name: 'work_order_work_order_list'
            },
            create: {
                name: 'work_order_work_order_create'
            },
            update: {
                name: 'work_order_work_order_update'
            },
            delete: {
                name: 'work_order_work_order_delete'
            }
        },
        completed_work_order: {
            name: 'work_order_completed_work_order',
            list: {
                name: 'work_order_completed_work_order_list'
            },
        }
    },
    inventory: {
        name: 'inventory',
        url: '//lot/adjustments',
        stock_adjustment: {
            name: 'inventory_stock_adjustment',
            list: {
                name: 'inventory_stock_adjustment_list'
            },
            create: {
                name: 'inventory_stock_adjustment_create'
            },
        }
    },
    reports: {
        name: 'reports',
        url: '/reports',
        inventory_wise_sales: {
            name: 'reports_inventory_wise_sales'
        },
        logs: {
            name: 'reports_log'
        },
        warehouse: {
            name: 'reports_warehouse'
        },
        sales_detail: {
            name: 'reports_sales_detail'
        },
        sales_return: {
            name: 'reports_sales_return'
        },
        payment_detail: {
            name: 'reports_payment_detail'
        },
        order_summary: {
            name: 'reports_order_summary'
        },
        supplier_returns: {
            name: 'reports_supplier_returns'
        },
        supplier_payments: {
            name: 'reports_supplier_payments'
        },
        purchase_bill: {
            name: 'reports_purchase_bill'
        },
        expense: {
            name: 'reports_expense'
        },
        vendor: {
            name: 'reports_vendor'
        },
        material: {
            name: 'reports_material'
        },
        consignment_delivery: {
            name: 'reports_consignment_delivery'
        },
        post_date_cheque: {
            name: 'reports_post_date_cheques'
        },
        trial_balance: {
            name: 'reports_trial_balance'
        },
        balance_sheet: {
            name: 'reports_balance_sheet'
        },
        gl_account: {
            name: 'reports_gl_account'
        },
        supplier_ledger: {
            name: 'reports_supplier_ledger'
        },
        pnl: {
            name: 'reports_pnl'
        },
        customer_ledger: {
            name: 'reports_customer_ledger'
        },
        pre_order: {
            name: 'reports_pre_order'
        }
    },
    settings: {
        name: 'settings',
        url: '/setting',
        settings: {
            name: 'settings_settings'
        },
        configure_work_order: {
            name: 'settings_configure_work_order'
        }
    }

}

export {Permissions}