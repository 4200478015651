import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  downLink: any;
  effectiveType: any;
  constructor(private snackbar: MatSnackBar) {

  }

  ngOnInit(): void {
    this.downLink = navigator['connection'].downlink;
    this.effectiveType = navigator['connection'].effectiveType;
    // if (this.downLink < 2 || this.effectiveType == 'slow-2g' || this.effectiveType == '2g' || this.effectiveType == '3g') {
    //   this.snackbar.open('Slow Interent', 'close', {
    //     panelClass: ['customClass']
    //   });
    // }
  }
}
