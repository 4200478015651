import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from "../_services";
import {RouteConstant} from '../shared/config/route-constant';
import { CheckPermission } from '../_utils';

// import {Promise} from 'bluebird';


@Injectable()
export class PermissionGuard implements CanActivate {

  routeConfig:any;
  constructor(
    private router                : Router,
    private authenticationService : AuthenticationService,
    private routeConstant         : RouteConstant
  ) {
    this.routeConfig = routeConstant.get();

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const module = route.data.module

    if(!module) return true

    return CheckPermission(module)

  }

}


