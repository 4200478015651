import { Injectable } from '@angular/core';
import {
  Resolve, RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { map, take } from 'rxjs/operators'
import { CategoryService, DashboardService, ProductService } from '../_services';

@Injectable()
export class DashboardResolver implements Resolve<any> {

  constructor(private dashboardservice: DashboardService, private categoryservice: CategoryService, private productservice: ProductService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return forkJoin(
      this.dashboardservice.getProductAssignmentList(),
      this.dashboardservice.getStockList(),
      this.dashboardservice.getTeamAssignmentList(),
      this.dashboardservice.getWorkOrderAssignmentList(),
      this.dashboardservice.getWorkOrderList(),
      this.categoryservice.getCategory(),
      this.productservice.getProduct(),
      this.dashboardservice.getTeams().pipe(
        take(1),
        map((data: any) => {
          if (data) {
            return data;
          } else {
            return null;
          }
        })
      )).pipe(
        map(([productassignment, stocks, teamassignment, workorderassignment, workorders, category, products, teams]) => {
          // combineLatest returns an array of values, here we map those values to an object
          return { productassignment, stocks, teamassignment, workorderassignment, workorders, category, products, teams };
        })
      );
  }

}
