import { Injectable } from '@angular/core';
import {
    Resolve, RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { map, take } from 'rxjs/operators'
import { CustomerService, } from '../_services';

@Injectable()
export class CustomerResolver implements Resolve<any> {

    constructor(private customerservice: CustomerService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return forkJoin(
            this.customerservice.getCustomer(),
            //   this.customerservice.getStockList()).pipe(
            //     map(([orders, stocks]) => {
            //       // combineLatest returns an array of values, here we map those values to an object
            //       return { orders, stocks };
            //     })
        );
    }
}

