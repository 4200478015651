import { Injectable } from '@angular/core';
import {
  Resolve, RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { map, take } from 'rxjs/operators'



import { ProductService, MaterialService, CategoryService, WorkOrderService, TeamService, WorkOrderConfigService, CustomerService } from '../_services';

@Injectable()
export class WorkOrderResolver implements Resolve<any> {

  constructor(
    private customerservice: CustomerService,
    private productservice: ProductService, private materialservice: MaterialService, private categoryservice: CategoryService, private workorderservice: WorkOrderService, private teamservice: TeamService, private workorderconfigservice: WorkOrderConfigService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return forkJoin(
      this.productservice.getProduct(),
      this.materialservice.getMaterial(),
      this.categoryservice.getCategory(),
      this.workorderservice.getWorkOrderList(),
      this.teamservice.getTeam(),
      this.workorderconfigservice.getWorkOrderOperationsType(),
      this.customerservice.getCustomer().pipe(
        take(1),
        map((data: any) => {
          if (data) {
            return data;
          } else {
            return null;
          }
        })
      )).pipe(
        map(([products, materials, category, workorders, teams, operationtypes, customers]) => {
          // combineLatest returns an array of values, here we map those values to an object
          return { products, materials, category, workorders, teams, operationtypes, customers };
        })
      );

  }

}
