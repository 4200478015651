import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from './shared/shared.module';
import { TemplateModule } from './template/template.module';
import { TemplateService } from './shared/services/template.service';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { EnlargePictureComponent } from './dynamic/modal/enlarge-picture/enlarge-picture.component';
import { MatDialogModule } from '@angular/material';

import {
  UserService,
  AuthenticationService,
  ProductService,
  LocationService,
  TeamService,
  DashboardService,
  UserNotificationService,
  PermissionService,
  ExcelService
} from './_services';
import { UserResolver, WorkOrderResolver, AuthGuard, DashboardResolver, UserNotificationResolver } from './_guards';

// Layout Component
import { CommonLayoutComponent } from './common/common-layout.component';
import { AuthenticationLayoutComponent } from './common/authentication-layout.component';
import { SignInComponent } from './extras/sign-in/sign-in.component';
import { Page404Component } from './extras/404/404.component';
import { SignOutComponent } from './signout';

// Routing Module
import { AppRoutes } from './app.routing';

// App Component
import { AppComponent } from './app.component';
import { APIConstants } from './shared/config/api-constant';
import { RouteConstant } from './shared/config/route-constant';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NewDashboardComponent } from './new-dashboard/new-dashboard.component';
import { FinancialDashboardService } from './_services/financial-dashboard.service';
import { PermissionGuard } from './_guards/permission.guard';

@NgModule({
  imports: [
    HttpModule,
    BrowserModule,
    RouterModule.forRoot(AppRoutes, { useHash: true }),
    SharedModule,
    TemplateModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatDialogModule

  ],
  declarations: [
    AppComponent,
    CommonLayoutComponent,
    AuthenticationLayoutComponent,
    SignInComponent,
    Page404Component,
    SignOutComponent,
    EnlargePictureComponent,
    NewDashboardComponent,
  ],
  providers: [
    AuthGuard,
    PermissionGuard,
    TemplateService,
    UserResolver,
    UserService,
    AuthenticationService,
    APIConstants,
    RouteConstant,
    ProductService,
    LocationService,
    WorkOrderResolver,
    TeamService,
    DashboardService,
    DashboardResolver,
    UserNotificationResolver,
    UserNotificationService,
    FinancialDashboardService,
    PermissionService,
    ExcelService
  ],
  bootstrap: [AppComponent],
  entryComponents: [EnlargePictureComponent],
})


export class AppModule {
}
