import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError, of } from 'rxjs';
import { APIConstants } from '../shared/config/api-constant';

@Injectable()
export class LotService {
  private API: any;
  private currentUser: any;
  private accessToken: string;

  constructor(private http: HttpClient, private APIConfig: APIConstants) {
    this.API = APIConfig.get();
    this.currentUser = JSON.parse(localStorage.getItem('currentUserZahra'));
    this.accessToken = `Bearer ${this.currentUser.accessToken}`;
  }

  private getUrl(endpoint: string, params: string[] = []) {
    return this.API.BASE_URL + endpoint + ((params.length > 0) ? '/' + params.join('/') : '');
  }


  getBarCode(barcode, locationId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http.post<any>(this.getUrl('/lot/lot_item') + `/${barcode}/${locationId}`, httpOptions);
  }


  getBarCodeInOrder(barcode, locationId, product_id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http.post<any>(this.getUrl('/lot/lot_item_by_product') + `/${barcode}/${locationId}/${product_id}`, httpOptions);
  }


  getLotList() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http
      .post<any>(this.getUrl(this.API.GET_LOT), httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data.data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('No Work Order Found', []))
      );
  }

  getLotHistory(workorderId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http
      .get<any>(this.getUrl(this.API.GET_WORK_ORDER_DETAILS, [workorderId.toString()]), httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data.data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('No Work Order Found', []))
      );
  }

  getLotHistoryOperationTitle(workorderOperationId) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http
      .get<any>(this.getUrl(this.API.GET_WORK_ORDER_OPERATION, [workorderOperationId.toString()]), httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data.data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('No Work Order Found', []))
      );
  }

  getLotSingle(lot_id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http
      .get(this.getUrl(this.API.GET_LOT_SINGLE, [lot_id.toString()]), httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data.data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('No Work Order Found', []))
      );
  }

  editLot(lot_id: any, lots: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http
      .put(this.getUrl(this.API.EDIT_LOT, [lot_id.toString()]), lots, httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data.data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('Unable Edit Lots Found', []))
      );
  }

  createLot(lots: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };
    return this.http
      .post<any>(this.getUrl(this.API.CREATE_LOT), lots, httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data.data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('Unable Edit Lots Found', []))
      );
  }

  createStockAdjustment(instockadjustment: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http
      .post<any>(this.getUrl(this.API.CREATE_ADJUSTMENT_LOT), instockadjustment, httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('Unable Edit Lots Found', []))
      );
  }

  getStockAdjustmentList() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http
      .post<any>(this.getUrl(this.API.STOCK_ADJUSTMENT_LIST), httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data.data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('Unable Get Stock Adjustment', []))
      );
  }

  getStockAdjustmentListDate(date: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    let url = this.API.BASE_URL + this.API.STOCK_ADJUSTMENT_LIST +
      `${date && `?from_date=${date.from}&to_date=${date.to}`}`;

    return this.http
      .post<any>(url, httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data.data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('Unable Get Stock Adjustment', []))
      );
  }

  fetchLot(lots: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http
      .post<any>(this.getUrl(this.API.FETCH_LOT_OUT_DETAILS), lots, httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('Unable Edit Lots Found', []))
      );
  }

  deleteLot(lot_Id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http
      .delete(this.getUrl(this.API.DELETE_LOT, [lot_Id.toString()]), httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data.data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('Unable to delete Lot', []))
      );
  }

  private handleErrorRecord<T>(operation = 'operation', result?: T) {
    return (error: any): any => {

      // TODO: send the error to remote logging infrastructure
      console.log(error.error); // log to console instead

      if (error.error.summary === 'Record Not Found' || error.error.summary === 'Requested API Not Found') {
        return of([]);
      }

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(error.error as T);
    };
  }

  private handleError(error: HttpErrorResponse) {
    let err;
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      err = 'An error occurred: ' + error.error.message;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      err = error;
    }
    console.log(err);
    // return an observable with a user-facing error message
    // return throwError('Something bad happened; please try again later.');
    return throwError(err);
  }
}
