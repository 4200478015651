import { Http } from '@angular/http';
import {Injectable} from '@angular/core';
import {
  Resolve, RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';



import { LocationService } from '../_services';

@Injectable()
export class LocationResolver implements Resolve<any> {

  constructor(private http: Http, private locationservice: LocationService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.locationservice.getLocations();
  }

}
