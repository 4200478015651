import { Injectable } from '@angular/core';
import {
  Resolve, RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { map, take } from 'rxjs/operators'
import { OrderService, } from '../_services';

@Injectable()
export class SupplierResolver implements Resolve<any> {

  constructor(private orderservice: OrderService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    // return forkJoin(
    //   this.orderservice.getOrder(),
    //   this.orderservice.getStockList()).pipe(
    //     map(([orders, stocks]) => {
    //       // combineLatest returns an array of values, here we map those values to an object
    //       return { orders, stocks };
    //     })
    //   );
    return
  }
}

