import { Injectable } from '@angular/core';
import {
  Resolve, RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { map, take } from 'rxjs/operators'
import { ProductService, MaterialService, CategoryService } from '../_services';

@Injectable()
export class ProductResolver implements Resolve<any> {

  constructor(private productservice: ProductService, private materialservice: MaterialService, private categoryservice: CategoryService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return forkJoin(
      this.productservice.getProductPagination(300,0,"active"),
      this.materialservice.getMaterial(),
      this.categoryservice.getCategorybyProducts().pipe(
        take(1),
        map((data: any) => {
          if (data) {
            return data;
          } else {
            return null;
          }
        })
      )).pipe(
        map(([products, materials, category]) => {
          // combineLatest returns an array of values, here we map those values to an object
          return { products, materials, category };
        })
      );
  }
}
