import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError, of } from 'rxjs';
import { APIConstants } from '../shared/config/api-constant';

@Injectable()
export class ProductService {
  private API: any;
  private currentUser: any;
  private accessToken: string;

  constructor(private http: HttpClient, private APIConfig: APIConstants) {
    this.API = APIConfig.get();
    this.currentUser = JSON.parse(localStorage.getItem('currentUserZahra'));
    this.accessToken = `Bearer ${this.currentUser.accessToken}`;
  }

  private getUrl(endpoint: string, params: string[] = [], query_params: string[] = []) {
    return this.API.BASE_URL + endpoint + ((params.length > 0) ? '/' + params.join('/') : '') + ((query_params.length > 0) ? '?' + query_params.join('&') : '');
  }

  getProduct(location_id = null) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    let url = '';

    if (location_id) {
      url = this.getUrl(this.API.GET_PRODUCTS, [], [location_id])
    } else {
      url = this.getUrl(this.API.GET_PRODUCTS)
    }

    return this.http
      .get(url, httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data.data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('No Product Found', []))
      );
  }

  getProductPagination(limit: any,
    offset: any,statusType:string,search?:any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    let url = this.API.BASE_URL + this.API.GET_PRODUCTS +
      `${limit && `?limit=${limit}`}${offset !== null && `&offset=${offset}${statusType ? `&filter=${statusType}` : ''}${search ? `&search=${search}` : ''}`
      }`;
    return this.http
      .get(url, httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('No Product Found', []))
      );
  }

  getSinglelProduct(product_Id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http
      .get(this.getUrl(this.API.GET_PRODUCT, [product_Id.toString()]), httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data.data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('No Product Found', []))
      );
  }

  addProduct(Product: any) {
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Authorization': this.accessToken
    //   })
    // };

    return this.http
      .post<any>(this.getUrl(this.API.CREATE_PRODUCT), Product)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return true;
          }
          return false;
        })
      ).pipe(
        catchError(this.handleErrorRecord('Add Product Error', []))
      );
  }

  addCustomProduct(Product: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http
      .post<any>(this.getUrl(this.API.CREATE_PRODUCT), Product, httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('Add Product Error', []))
      );
  }

  addProductMultipleImage(Image: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http
      .post<any>(this.getUrl(this.API.UPLOAD_MULTIPLE_IMAGE), Image, httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('Upload Product Image Error Product Error', []))
      );
  }

  addProductFiles(Document: any) {
    return this.http
      .post<any>(this.getUrl(this.API.UPLOAD_DOCUMENT_PRODUCT), Document)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return data;
          }
          return data;
        })
      ).pipe(
        catchError(this.handleErrorRecord('Upload Product Document Error Product Error', []))
      );
  }

  editProduct(Product_Id: any, Product: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http
      .put<any>(this.getUrl(this.API.EDIT_PRODUCT, [Product_Id.toString()]), Product, httpOptions)
      .pipe(
        map((data: any) => {

          console.log('here', data);

          if (data.success) {
            return true;
          }
          return false;
        })
      )
  }

  deleteProduct(Product_Id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http
      .delete<any>(this.getUrl(this.API.DELETE_PRODUCT, [Product_Id.toString()]), httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return true;
          }
          return false;
        })
      ).pipe(
        catchError(this.handleErrorRecord('Edit Product Error', []))
      );
  }

  updateProductStatus(Product_Id: any, Status: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http
      .get<any>(this.getUrl(this.API.EDIT_PRODUCT_STATUS + Product_Id + "/" + Status), httpOptions)
      .pipe(
        map((data: any) => {
          if (data.success) {
            return true;
          }
          return false;
        })
      ).pipe(
        catchError(this.handleErrorRecord('Edit Product Error', []))
      );

  }

  private handleErrorRecord<T>(operation = 'operation', result?: T) {
    return (error: any): any => {

      // TODO: send the error to remote logging infrastructure
      console.log(error.error); // log to console instead

      if (error.error.summary === 'Record Not Found' || error.error.summary === 'Requested API Not Found') {
        return of([]);
      }


      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(error.error as T);
    };
  }

  private handleError(error: HttpErrorResponse) {
    let err;
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      err = 'An error occurred: ' + error.error.message;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      err = error;
    }
    console.log(err);
    // return an observable with a user-facing error message
    // return throwError('Something bad happened; please try again later.');
    return throwError(err);
  }
}
