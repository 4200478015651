import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError, of } from 'rxjs';
import { APIConstants } from '../shared/config/api-constant';

export interface AccountObj {
  account_nature    : string,
  account_code      : string,
  account_type      : number,
  description       : string,
  account_title     : string,
  parent_account_id : number,
  is_active         : boolean
}

@Injectable()
export class GLAccountService {
  private API         : any;
  private currentUser : any;
  private accessToken : string;

  constructor(private http: HttpClient, private APIConfig: APIConstants) {
    this.API = APIConfig.get();
    this.currentUser = JSON.parse(localStorage.getItem('currentUserZahra'));
    this.accessToken = `Bearer ${this.currentUser.accessToken}`;
  }

  private getUrl(endpoint: string, params: string[] = []) {
    return this.API.BASE_URL + endpoint + ((params.length > 0) ? '/' + params.join('/') : '');
  }

  getGLAccountHead() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http.get<any>(this.getUrl(this.API.GET_GL_ACCOUNTS_HEAD), httpOptions)
  }

  getGLAccounts() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http.get<any>(this.getUrl(this.API.GET_GL_ACCOUNTS), httpOptions)
  }

  getGLAccountById(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http.get<any>(this.getUrl(this.API.GET_GL_ACCOUNTS)+'/'+id, httpOptions)
  }

  getDetailedGLAccounts(){
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http.get<any>(this.getUrl(this.API.GET_GL_ACCOUNTS)+'/detail', httpOptions)
  }

  createGlAccount(payload: AccountObj) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http.post<any>(this.getUrl(this.API.CREATE_GL_ACCOUNT), payload, httpOptions)
  }

  updateGlAccount(payload, id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.accessToken
      })
    };

    return this.http.put<any>(this.getUrl(this.API.GET_GL_ACCOUNTS)+'/'+id, payload, httpOptions)
  }

}
