import { Routes } from '@angular/router';


// Layouts
import { CommonLayoutComponent } from './common/common-layout.component';
import { AuthenticationLayoutComponent } from './common/authentication-layout.component';
import { SignInComponent } from './extras/sign-in/sign-in.component';
import { UserResolver, AuthGuard, WorkOrderResolver, DashboardResolver, UserNotificationResolver } from './_guards';
import { Page404Component } from './extras/404/404.component';
import { SignOutComponent } from './signout';
import { NewDashboardComponent } from './new-dashboard/new-dashboard.component';

import { PermissionGuard } from './_guards/permission.guard';
import { Permissions } from './_enums';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: SignInComponent,
  },
  {
    path: 'logout',
    component: SignOutComponent,
  },
  {
    path: '',
    component: CommonLayoutComponent,
    resolve: {
      data: UserNotificationResolver
    },
    children: [
      {
        path         : 'dashboard',
        loadChildren : './dashboard/dashboard.module#DashboardModule',
        canActivate  : [AuthGuard, PermissionGuard],
        data         : {module: Permissions.dashboard.name},
        resolve      : {
          data: DashboardResolver
        }
      },
      {
        path         : 'dashboard-team',
        loadChildren : './dashboard-team/dashboard-team.module#DashboardTeamModule',
        canActivate  : [AuthGuard, PermissionGuard],
        data         : {module: Permissions.dashboard.team.name},
        resolve      : {
          data: DashboardResolver
        }
      },
      {
        path: 'financial-dashboard',
        // loadChildren: './dashboard-team/dashboard-team.module#DashboardTeamModule',
        component   : NewDashboardComponent,
        canActivate : [AuthGuard, PermissionGuard],
        data        : {module: Permissions.dashboard.financial_dashboard.name},
        resolve     : {
          data: DashboardResolver
        }
      },
      {
        path         : 'dashboard-team-performance',
        loadChildren : './dashboard-team-performance/dashboard-team-performance.module#DashboardTeamPerformanceModule',
        canActivate  : [AuthGuard, PermissionGuard],
        data         : {module: Permissions.dashboard.team_performance.name},
        resolve      : {
          data: DashboardResolver
        }
      },
      {
        path         : 'product',
        loadChildren : './product/product.module#ProductModule',
        canActivate  : [AuthGuard, PermissionGuard],
        data         : {module: Permissions.product.name}
      },
      {
        path: 'order',
        // loadChildren: './order/order.module#OrderModule',
        loadChildren: () => import('./order/order.module').then(res => res.OrderModule),

        canActivate : [AuthGuard, PermissionGuard],
        data        : {module: Permissions.order.name}
      },
      {
        path         : 'supplier',
        loadChildren : () => import('./purchases/purchases.module').then(res => res.SupplierModule),
        canActivate  : [AuthGuard, PermissionGuard],
        data         : {module: Permissions.purchases.supplier.name}
      },
      {
        path: 'change-pass',
        loadChildren: () => import('./change-password/change-password.module').then(res => res.ChangePassModule),
        canActivate: [AuthGuard]
      },
      {
        path         : 'account-management',
        loadChildren : () => import('./account-management/account-management.module').then(res => res.AccountManagementModule),
        canActivate  : [AuthGuard, PermissionGuard],
        data         : {module: Permissions.account_management.name}
      },
      {
        path         : 'expense-management',
        loadChildren : () => import('./expense-management/expense-management.module').then(res => res.ExpenseManagementModule),
        canActivate  : [AuthGuard, PermissionGuard],
        data         : {module: Permissions.expense_management.name}
      },
      {
        path         : 'process-management',
        loadChildren : () => import('./gl-process-management/gl-process-management.module').then(res => res.GlProcessManagementModule),
        canActivate  : [AuthGuard]
      },
      {
        path         : 'tax-management',
        loadChildren : () => import('./tax-management/tax-management.module').then(res => res.TaxManagementModule),
        canActivate  : [AuthGuard, PermissionGuard],
        data         : {module: Permissions.tax_management.name}
      },
      {
        path         : 'cash-management',
        loadChildren : () => import('./cash-management/cash-management.module').then(res => res.CashManagementModule),
        canActivate  : [AuthGuard, PermissionGuard],
        data         : {module: Permissions.cash_management.name}
      },
      {
        path         : 'customer',
        loadChildren : () => import('./customers/customers.module').then(res => res.CustomersModule),
        canActivate  : [AuthGuard, PermissionGuard],
        data         : {module: Permissions.customers.name}
      },
      {
        path         : 'shipping',
        loadChildren : () => import('./shipping/shipping.module').then(res => res.ShippingModules),
        canActivate  : [AuthGuard, PermissionGuard],
        data         : {module: Permissions.shipping.name}
      },
      {
        path         : 'location',
        loadChildren : './location/location.module#LocationModule',
        canActivate  : [AuthGuard, PermissionGuard],
        data         : {module: Permissions.locations.name}
      },
      {
        path         : 'workOrder',
        loadChildren : './work-order/work-order.module#WorkOrderModule',
        canActivate  : [AuthGuard, PermissionGuard],
        data         : {module: Permissions.work_order.name}
      },
      {
        path         : 'material',
        loadChildren : './material/material.module#MaterialModule',
        canActivate  : [AuthGuard, PermissionGuard],
        data         : {module: Permissions.material.name}
      },
      {
        path         : 'category',
        loadChildren : './category/category.module#CategoryModule',
        canActivate  : [AuthGuard, PermissionGuard],
        data         : {module: Permissions.category.name}
      },
      {
        path         : 'team',
        loadChildren : './team/team.module#TeamModule',
        canActivate  : [AuthGuard, PermissionGuard],
        data         : {module: Permissions.team.name}
      },
      {
        path: 'notification',
        loadChildren: './notifications/notification.module#NotificationModule',
        canActivate: [AuthGuard]
      },
      {
        path         : 'user',
        loadChildren : './user/user.module#UserModule',
        canActivate  : [AuthGuard, PermissionGuard],
        data         : {module: Permissions.users.name}
      },
      {
        path         : 'inventoryTransaction',
        loadChildren : './inventory-transaction/inventory-transaction.module#InventoryTransactionModule',
        canActivate  : [AuthGuard],
        // data         : {module: Permissions.expense_management.name}
      },
      {
        path         : 'lot',
        loadChildren : './lot/lot.modules#LotModules',
        canActivate  : [AuthGuard, PermissionGuard],
        data         : {module: Permissions.inventory.name}
      },
      {
        path         : 'roles',
        loadChildren : './userroles/user-roles.module#UserRolesModule',
        canActivate  : [AuthGuard, PermissionGuard],
        data         : {module: Permissions.users.user_roles.name}
      },
      {
        path         : 'setting',
        loadChildren : './setting/setting.module#SettingModule',
        canActivate  : [AuthGuard, PermissionGuard],
        data         : {module: Permissions.settings.name}
      },
      {
        path         : 'reports',
        loadChildren : './reports/reports.module#ReportsModule',
        canActivate  : [AuthGuard, PermissionGuard],
        data         : {module: Permissions.reports.name}
      },
    ]
  },
  {
    path: '',
    component: AuthenticationLayoutComponent,
    children: [
      {
        path: 'authentication',
        loadChildren: './extras/authentication.modules#AuthenticationModule',
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: '**',
    component: Page404Component
  }
];

