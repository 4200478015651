import {Component} from '@angular/core';
import {TemplateService} from '../../shared/services/template.service';
import {Router, ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  isSeen: boolean = false;
  isCollapse: boolean;
  isOpen: boolean;
  title: any;
  searchModel: string;
  searchActived = false;
  username: any;
  users:any [] =[];
  notifications: any[] = [];
  notificationCount:any;
  user_image:any;

  admin_dropdown = false

  constructor(
    private tplSvc?: TemplateService,
    private router?: Router,
    private route?: ActivatedRoute,
    ) {
  }


  onError() {
    $('#userAvatar').attr('src','assets/images/avatars/image.png');
  }

  ngOnInit(): void {
    this.tplSvc.isSideNavCollapseChanges.subscribe(isCollapse => this.isCollapse = isCollapse);
    this.tplSvc.isSidePanelOpenChanges.subscribe(isOpen => this.isOpen = isOpen);

    let user: any = JSON.parse(localStorage.getItem('currentUserZahra'));
    this.username = user.first_name.toString().toUpperCase();
    this.user_image = user.image_path;



    this.route.data.subscribe(
      (data: any) => {

        data.data.users.map((user) =>{
          this.users.push({
            id: user.id,
            name: user.first_name
          })
        });

        let userSelected;

        this.notificationCount = data.data.notifications.length;
        if(data.data.notifications && data.data.notifications.length > 0){
          data.data.notifications.sort(function(o){ return new Date(o.createdAt ) });
          data.data.notifications.reverse();
        }

        data.data.notifications.slice(0, 9).map((notification) => {

          userSelected = this.users.filter((user) => user.id == notification.to_user_id);


          this.notifications.push({
            id: notification.id,
            notificationTitle: notification.title,
            from: userSelected[0].name,
            createdAt: notification.created_at,
            message: notification.from_user_id == 0 ? "Work Order Initiated" : notification.message,
            workorderId: notification.workorder_id,
            workorderStatus: notification.Workorder.is_active
          })

        });
      },
      error => console.log(error)
    );





  }


  toggleSideNavCollapse() {
    this.isCollapse = !this.isCollapse;
    this.tplSvc.toggleSideNavCollapse(this.isCollapse);
  }

  toggleSidePanelOpen() {
    this.isOpen = !this.isOpen;
    this.tplSvc.toggleSidePanelOpen(this.isOpen);
  }

  setTitle(title: string) {
    this.title = title;
  }

  toggleSearch() {
    this.searchActived = !this.searchActived;
    console.log(this.searchActived);
  }

  notificationPanel(){
   this.isSeen = true;
  }

  handleDropDown(){
    this.admin_dropdown = !this.admin_dropdown
  }

  logout(){
    localStorage.clear()
    this.router.navigate(['/'])
  }

  changePass(){
    this.admin_dropdown = false
    this.router.navigate(['/change-pass/update'])
  }

}
