import { Component } from '@angular/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html'
})
export class FooterComponent  {
  date: any;

  constructor() {
    this.date = (new Date()).getFullYear();
  }
}
